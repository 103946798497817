<template>
  <CFooter :fixed="false" style="background: #FFFF;">
    <div >
      <!-- <a href="https://coreui.io" target="_blank">SIG SOFTWARE</a> -->
      <span class="ml-1">&copy; {{anio}} IM&RA Core Business.</span>
    </div>
    <div class="mfs-auto">
      <!-- <span class="mr-1" target="_blank">/span>
      <a href="https://coreui.io/vue">CoreUI for Vue</a> -->
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'ContentFooter',
     data() {
        return {
          anio: moment().format('YYYY')
        }}
}
</script>
